<template>
    <div class="flex flex-col items-start pt-10 px-8 w-full min-h-full flex-grow">
        <div class="w-full">
            <div class="mb-10 w-full">
                <h2 class="text-3xl font-bold mb-4">{{ config.layout.title }}</h2>
                <p class="text-gray-600">{{ config.layout.description }}</p>
            </div>
            <NotificationsBlueprint />
        </div>
        <Teleport :to="footerMenu?.$el">
            <div class="px-8 pb-6 flex flex-col bg-[var(--background)] w-full mt-auto" data-cy="tour-footer">
                <DButton class="mb-1" @click="onNotificationAction" wide>
                    {{ config.action_button_text }}
                </DButton>
                <DButton class="mb-1" @click="onSkipAction" theme="transparent" wide>
                    {{ config.skip_step_button_text }}
                </DButton>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
    import { DButton } from '@digistorm/spark'
    import NotificationsBlueprint from '@/components/ui/NotificationsBlueprint.vue'
    import { useTourStore } from '@/stores/tour'

    const emit = defineEmits(['action-skipped', 'action-completed'])

    const pushNotifications = usePushNotifications()
    const tourStore = useTourStore()

    const props = defineProps({
        config: Object,
    })

    const footerMenu = inject('footerMenu', null)

    const onNotificationAction = async () => {
        tourStore.handleAction(props.config.action_type, false)
        await pushNotifications.requestPermissionAndRegister()
        emit('action-completed', true)
    }

    const onSkipAction = () => {
        tourStore.handleAction(props.config.action_type, true)
        emit('action-skipped')
    }
</script>
