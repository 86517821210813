<template>
    <ListItem :config="configWithBadge" type="simple" />
</template>
<script setup>
    const props = defineProps({
        config: Object,
    })

    const notificationsStore = useNotificationsStore()
    const { unreadCount } = storeToRefs(notificationsStore)

    const configWithBadge = computed(() => {
        return {
            ...props.config,
            data: {
                ...props.config.data,
                unread_count: unreadCount.value,
            },
        }
    })
</script>
