<template>
    <IonHeader class="ion-no-border px-4 pt-6">
        <div class="mb-10 w-full">
            <h2 class="text-3xl font-bold mb-4">You may miss important updates.</h2>
            <p class="text-gray-600">Allow push notifications so we can let you know when something important happens at school.</p>
        </div>
    </IonHeader>
    <IonContent>
        <div class="px-4">
            <NotificationsBlueprint />
        </div>
    </IonContent>
    <IonFooter class="ion-no-border">
        <div class="px-8 pb-6 flex flex-col bg-[var(--background)] w-full mt-auto" data-cy="tour-footer">
            <DButton class="mb-1" @click="confirm" wide>
                Allow notifications
            </DButton>
            <DButton class="mb-1" @click="dismiss" theme="transparent" wide>
                Not now
            </DButton>
        </div>
    </IonFooter>
</template>

<script setup>
    import { modalController } from '@ionic/vue'
    import { DButton } from '@digistorm/spark'
    import NotificationsBlueprint from '@/components/ui/NotificationsBlueprint.vue'
    import { usePushNotifications } from '@/composables'

    const pushNotifications = usePushNotifications()

    const confirm = async () => {
        await modalController.dismiss(null, 'confirm')
        await pushNotifications.requestPermissionAndRegister()
    }

    const dismiss = async () => {
        await modalController.dismiss(null, 'cancel')
    }
</script>
