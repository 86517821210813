<template>
    <div data-cy="notifications" class="relative h-full text-gray-700 flex flex-col" :class="{ 'pt-[var(--beyond-safe-area-inset-top)]': !isNativePlatform }">
        <div v-if="!hideTitle" class="py-5 text-gray-900 font-medium text-xl text-center">
            Notifications
        </div>
        <div v-else class="mb-2" />

        <div v-if="loading" class="py-10 flex items-center justify-center h-full">
            <UILoading class="w-12 h-12" />
        </div>
        <div v-else-if="error" class="p-4">
            <Error
                :axiosError="error"
            />
        </div>
        <ModuleComponents
            v-else-if="component"
            :modules="[component]"
        />
    </div>
</template>

<script setup>
    import { useRoute } from 'vue-router'
    import { Capacitor } from '@capacitor/core'
    import { statusBar } from '@/services'
    import ModuleComponents from '@/components/ModuleComponents.vue'
    import { disableAutomaticRightIconKey } from '@/keys'
    import Error from '@/components/ui/Error.vue'

    const props = defineProps({
        hideTitle: Boolean,
    })

    const route = useRoute()

    const pushNotifications = usePushNotifications()
    const notificationsStore = useNotificationsStore()

    provide(disableAutomaticRightIconKey, true)

    const activated = ref(false)

    const { component, loading, error } = storeToRefs(notificationsStore)

    const isNativePlatform = computed(() => {
        return Capacitor.isNativePlatform()
    })

    const loadNotifications = () => {
        notificationsStore.fetch()
    }

    const pushNotificationReceived = (notification) => {
        // Clear out of notifications
        notificationsStore.lastFetched = null
        loadNotifications()
    }

    // When route changes to dashboard e.g. returning after viewing a notice, refresh the notifications
    // This view is kept alive so only load when it's currently activated
    watch(() => route.name, (name) => {
        if (name !== 'dashboard' || !activated.value) {
            return
        }
        loadNotifications()
    })

    onMounted(() => {
        statusBar.setTheme()

        pushNotifications.events.on('pushNotificationReceived', pushNotificationReceived)
    })

    onUnmounted(() => {
        pushNotifications.events.off('pushNotificationReceived', pushNotificationReceived)
    })

    onActivated(() => {
        activated.value = true
        statusBar.setTheme()

        notificationsStore.removeAllDeliveredNotifications()
            .catch((error) => {
                console.log('Unable to clear notifications', error)
            })

        // Make a request to update notifications
        loadNotifications()
    })

    onDeactivated(() => {
        activated.value = false
    })
</script>
