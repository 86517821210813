import mitt from 'mitt'
import dayjs from 'dayjs'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { modalController } from '@ionic/vue'
import { preferences } from '@/services'
import NotificationsModal from '@/components/ui/NotificationsModal.vue'
import { useAppStore, useVersionStore } from '@/stores'

const events = mitt()

export function usePushNotifications() {
    const available = Capacitor.isPluginAvailable('PushNotifications')
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const versionStore = useVersionStore()

    async function addListener(eventName, listener) {
        if (!available) {
            return
        }
        return PushNotifications.addListener(eventName, listener)
    }

    async function lastRemindedUserAt() {
        const date = await preferences.getItem('push_last_reminded_user_at')
        return date ? new Date(date) : null
    }

    async function updateLastRemindedUserAt() {
        await preferences.setItem({
            key: 'push_last_reminded_user_at',
            value: (new Date()).toISOString(),
        })
    }

    async function shouldRemindUser() {
        const shouldRequestPermission = await this.shouldRequestPushNotificationPermission()
        if (!shouldRequestPermission) {
            return false
        }

        const lastRemindedUserAt = await this.lastRemindedUserAt()

        // If we've reminded the user in the last week, don't remind them again
        if (lastRemindedUserAt && dayjs().diff(lastRemindedUserAt, 'days') < 7) {
            return false
        }

        return true
    }

    async function remindUser() {
        const shouldRemindUser = await this.shouldRemindUser()
        if (!shouldRemindUser || appStore.mockData) {
            return
        }

        await this.updateLastRemindedUserAt()

        const modal = await modalController.create({
            component: NotificationsModal,
            cssClass: 'ds-modal-notifications-sheet',
            componentProps: {},
            initialBreakpoint: 1,
            breakpoints: [0, 1],
        })

        await modal.present()
    }

    async function initTokenSync() {
        if (await this.disabledViaSettings()) {
            return
        }

        // Check if push notifications are enabled on the system level
        if (await this.enabled()) {
            await PushNotifications.register()
            await preferences.setItem({ key: 'push_previously_enabled', value: '1' })
        } else if (await preferences.getItem('push_previously_enabled')) {
            console.warn('Push notifications were enabled, but no longer are')
            await preferences.removeItem('push_previously_enabled')
            await authStore.pushUnregister()
        }
    }

    async function requestPermissionAndRegister() {
        if (!await this.shouldRequestPushNotificationPermission()) {
            console.warn('Should not request push notification permission')
            return
        }

        // This method only prompts the user for permission the first time
        // After that it just returns the current status
        const status = await PushNotifications.requestPermissions()
        if (status.receive !== 'granted') {
            return
        }

        await preferences.setItem({ key: 'push_previously_enabled', value: '1' })
        await PushNotifications.register()
    }

    async function shouldRequestPushNotificationPermission() {
        if (!available || await this.disabledViaSettings() || !versionStore.version?.notifications_enabled) {
            return false
        }

        const status = await PushNotifications.checkPermissions()
        console.log('Push notification status', status)

        // We shouldn't request permission if it's already granted or denied
        return status.receive !== 'denied'
            && status.receive !== 'granted'
    }

    async function enable() {
        await preferences.removeItem('push_disabled')
        await this.requestPermissionAndRegister()
    }

    async function disable() {
        await authStore.pushUnregister()
        await PushNotifications.unregister()
        await preferences.setItem({ key: 'push_disabled', value: '1' })
    }

    async function getDeliveredNotifications() {
        const notificationList = await PushNotifications.getDeliveredNotifications()
        console.log('Delivered notifications', notificationList)
        return notificationList
    }

    async function removeAllDeliveredNotifications() {
        if (!await this.enabled()) {
            return
        }
        return PushNotifications.removeAllDeliveredNotifications()
    }

    // Check if user has explicitly disabled via the settings toggle
    async function disabledViaSettings() {
        const pushDisabled = await preferences.getItem('push_disabled')
        return !!pushDisabled
    }

    async function enabled() {
        if (!available || await this.disabledViaSettings() || !versionStore.version?.notifications_enabled) {
            return false
        }
        const status = await PushNotifications.checkPermissions()
        console.log('Push notification status', status)
        return status.receive === 'granted'
    }

    function addListeners() {
        if (!available) {
            console.warn('Push notifications not supported')
            return
        }

        addListener('registration', async (token) => {
            console.info('Registration token: ', token.value)

            try {
                await authStore.pushRegister(token.value)
            } catch (e) {
                const { snackbar } = useSnackbars()
                snackbar({
                    message: 'Error registering for push notifications',
                    type: 'error',
                })

                events.emit('apiRegistrationError', e)
                throw e
            }

            events.emit('registration', token)
        })

        addListener('registrationError', (err) => {
            console.error('Registration error: ', err.error)
            events.emit('registrationError', err)
        })

        addListener('pushNotificationReceived', (notification) => {
            console.log('Push notification received: ', notification)
            events.emit('pushNotificationReceived', notification)
        })

        addListener('pushNotificationActionPerformed', (action) => {
            console.log('Push notification action performed', action.actionId, action.inputValue, action.notification)

            // There's an issue in android at the moment where the full notification payload isn't sent and ids don't match delivered
            // notifications, however we do get the data payload
            if (Capacitor.getPlatform() === 'android') {
                const payload = { ...action.notification.data, ...action.notification }
                action.notification.title = payload.title
                // Reach passes the notification body as data.message instead of data.body
                action.notification.body = payload.body ?? payload.message
                console.log('Push notification action payload modified', action.actionId, action.inputValue, action.notification)
            }

            events.emit('pushNotificationActionPerformed', action)
        })
    }

    return {
        available,
        disable,
        disabledViaSettings,
        enable,
        enabled,
        events,
        getDeliveredNotifications,
        initTokenSync,
        removeAllDeliveredNotifications,
        requestPermissionAndRegister,
        addListeners,
        shouldRequestPushNotificationPermission,
        lastRemindedUserAt,
        updateLastRemindedUserAt,
        shouldRemindUser,
        remindUser,
    }
}
