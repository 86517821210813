<template>
    <IonPage :style="{ backgroundColor: screen === 'homepage' ? backgroundColour : null }">
        <div class="flex flex-col h-screen" :class="{ 'pt-[var(--beyond-safe-area-inset-top)]': !isTransparentStatusBar || screen !== 'homepage' }">
            <div
                v-if="!isTransparentStatusBar && screen === 'homepage'"
                class="absolute top-0 inset-x-0 h-[var(--beyond-safe-area-inset-top)]"
                :style="{ backgroundColor: version.primary_color }"
            />
            <div class="relative flex-1 overflow-auto" ref="dashboardContainer" :style="{ backgroundColor: screen === 'homepage' ? backgroundColour : null }">
                <KeepAlive>
                    <component :is="activeScreen" :dashboard="dashboard" />
                </KeepAlive>
            </div>

            <DNotificationLayout class="dark d-snackbar-layout z-[99999] pb-20">
                <DNotification
                    :show="showVersionBar"
                    type="message"
                    @close="onVersionClose"
                >
                    <div class="font-medium mb-1">
                        Viewing demo version. <span class="underline" @click="revertVersion">Go back</span>
                    </div>
                </DNotification>
            </DNotificationLayout>

            <BottomBar />
        </div>

        <div v-if="appStore.mockData" class="absolute z-50 mt-2 top-[var(--beyond-safe-area-inset-top)] right-2 text-white rounded-full bg-red-500 w-5 h-5 flex items-center opacity-50 justify-center">
            <DIcon icon="code-bracket" size="w-4 h-4" />
        </div>
    </IonPage>
</template>

<script setup>
    import { useElementSize } from '@vueuse/core'
    import { DIcon, DNotification, DNotificationLayout } from '@digistorm/spark'
    import { analytics, mock, statusBar } from '@/services'
    import { useTourStore } from '@/stores/tour'

    import Homepage from '@/components/dashboard/Homepage.vue'
    import More from '@/components/dashboard/More.vue'
    import Notifications from '@/components/dashboard/Notifications.vue'
    import Identity from '@/components/dashboard/Identity.vue'

    const router = useIonRouter()
    const dashboardContainer = ref()
    const { height: dashboardHeight } = useElementSize(dashboardContainer)

    provide('dashboardHeight', dashboardHeight)

    const appStore = useAppStore()
    const dashboardStore = useDashboardStore()
    const moduleStore = useModuleStore()
    const versionStore = useVersionStore()
    const badgeStore = useBadgeStore()
    const tourStore = useTourStore()
    const pushNotifications = usePushNotifications()
    const { homepageAnimation } = useAnimations()

    const { dashboard, screen } = storeToRefs(dashboardStore)
    const { version } = storeToRefs(versionStore)

    const hasReadItems = ref(false)
    const showVersionBar = ref(appStore.draftVersion !== null)
    const hasShownTour = ref(false)

    const handleReadItem = () => {
        hasReadItems.value = true
    }

    const isTransparentStatusBar = computed(() => {
        return get(dashboard.value, 'data.transparent_status_bar')
    })

    const badgeRefreshTimeout = ref(null)

    const scheduleBadgeRefresh = () => {
        console.log('Scheduling badge refresh')
        if (badgeRefreshTimeout.value) {
            clearTimeout(badgeRefreshTimeout.value)
        }
        // eslint-disable-next-line no-use-before-define
        badgeRefreshTimeout.value = setTimeout(badgeRefresh, 5 * 60 * 1000)
    }

    const badgeRefresh = () => {
        console.log('Refreshing dashboard badges')
        badgeStore.refreshAll()
        scheduleBadgeRefresh()
    }

    const onVersionClose = () => {
        showVersionBar.value = false
    }

    const revertVersion = () => {
        appStore.resetDraftVersion()
        appStore.restart()
    }

    const resetVersionVisibility = () => {
        if (appStore.draftVersion !== null) {
            showVersionBar.value = true
        }
    }

    const activeScreen = computed(() => {
        switch (screen.value) {
            case 'more':
                return More
            case 'notifications':
                return Notifications
            case 'identity':
                return Identity
            case 'dashboard':
            default:
                return Homepage
        }
    })

    const backgroundColour = computed(() => {
        if (dashboard.value.data.background_color) {
            return dashboard.value.data.background_color
        }
        return version.value.background_color
    })

    // If version changes, show version bar
    watch(() => appStore.draftVersion, (val) => {
        showVersionBar.value = val !== null
    })

    watch(screen, (val) => {
        if (val === 'dashboard' || val === 'homepage') {
            resetVersionVisibility()
        }
    })

    onMounted(() => {
        scheduleBadgeRefresh()
        events.on('read', handleReadItem)

        analytics.setScreenName({ screenName: 'Home' })

        // If mocking set the screen accordingly
        if (mock.mockingMore()) {
            screen.value = 'more'
        }

        if (mock.mockingNotifications()) {
            screen.value = 'notifications'
        }
    })

    onUnmounted(() => {
        if (badgeRefreshTimeout.value) {
            clearTimeout(badgeRefreshTimeout.value)
        }
        events.off('read', handleReadItem)
    })

    onIonViewWillEnter(async () => {
        if (screen.value === 'homepage') {
            // Check primary color is light or dark and change the status bar
            statusBar.setTheme(version.value.primary_color)
            resetVersionVisibility()
        } else {
            statusBar.setTheme()
        }

        // Check if there have been any items read
        if (hasReadItems.value) {
            hasReadItems.value = false
            badgeRefresh()
        }

        // Launch the tour
        const tourStoreModule = await tourStore.getFirstStepModule()
        if (tourStoreModule && !hasShownTour.value) {
            hasShownTour.value = true
            moduleStore.addModule(tourStoreModule)
            router.push({ name: 'view', params: { id: tourStoreModule.id } }, homepageAnimation)

            // Prevent further execution
            return
        }

        // Remind the user to enable push notifications
        pushNotifications.remindUser()
    })
</script>
