<template>
    <template v-for="(item) in modulesWithComponent" :key="item.id">
        <KeepAlive>
            <component
                :is="item.component"
                :config="item.config"
                :data-cy="`module-component-${item.config.component}`"
                :filter="nestedFilter ? filter : null"
                :search-term="searchTerm"
                :search-properties="searchProperties"
                :is-remote-search="isRemoteSearch"
            />
        </KeepAlive>
    </template>
    <template v-if="isEmpty">
        <slot name="empty" />
    </template>
</template>

<script setup>

    const props = defineProps({
        modules: Array,
        filter: Function,
        nestedFilter: Boolean,
        paginationUrl: String,
        searchTerm: String,
        searchProperties: Array,
        isRemoteSearch: Boolean,
    })

    const moduleHelpers = useModuleHelpers()

    const modulesWithIds = ref(null)

    const modulesWithComponent = computed(() => {
        return modulesWithIds.value.map((module) => ({
            id: module.id,
            component: module.component,
            config: module,
        }))
    })

    const isEmpty = computed(() => {
        return modulesWithComponent.value.length === 0
    })

    // Adding ids in watcher instead of computed property
    // Computed properties are recalculated in some situations and we don't want to generate new ids
    watch(() => props.modules, (modules) => {
        modulesWithIds.value = moduleHelpers.addIdsToModules(modules ?? [])
    }, { immediate: true })

</script>
