import { defineStore } from 'pinia'
import {
    difference, each, forEach, get, isEmpty, keys, pull, sumBy, uniq,
} from 'lodash'
import { api, mock } from '@/services'
import { events } from '@/composables/events'

export const useBadgeStore = defineStore('badge', {
    state: () => {
        return {
            badges: {},
            pendingPromisesByUrl: {},
            failedPermanently: [],
            failedAuth: [],
        }
    },
    getters: {
        getBadgeCountForUrl(state) {
            const getCount = (url, alreadyCounted = []) => {
                const badge = state.badges[url]

                // Avoid infinite loops by keeping track of already counted urls
                if (!badge || alreadyCounted.includes(url)) {
                    return 0
                }

                alreadyCounted.push(url)

                if (!isEmpty(badge.badge_urls)) {
                    let settled = true
                    const count = sumBy(badge.badge_urls, (childUrl) => {
                        if (!state.settledUrls.includes(childUrl)) {
                            settled = false
                            return null
                        }
                        return getCount(childUrl, alreadyCounted)
                    })

                    // Don't show a count if the requests haven't settled
                    return settled ? count : null
                }

                return badge.count
            }

            return getCount
        },
        settledUrls(state) {
            return [
                ...keys(state.badges),
                ...state.failedPermanently,
                ...state.failedAuth,
            ]
        },
    },
    actions: {
        async decrementCount(moduleUrl) {
            const badge = this.badges[`${moduleUrl}/badge`]
            if (!badge || !isEmpty(badge.badge_urls)) {
                return
            }
            badge.count = Math.max(0, badge.count - 1)
        },
        async fetchUnsettled(url) {
            if (this.settledUrls.includes(url)) {
                return
            }
            this.fetch(url)
        },
        async fetch(url, alreadyFetched = []) {
            // Disable badge fetching when mocking
            if (mock.mocking() && !mock.mockingDashboard()) {
                return
            }

            // Avoid infinite loops by keeping track of already fetched urls
            if (alreadyFetched.includes(url)) {
                return
            }

            // If the url is in the failed permanently list reject the promise
            if (this.failedPermanently.includes(url)) {
                return Promise.reject(new Error('Url does not support badges'))
            }

            // If we're already fetching this url return the promise
            if (this.pendingPromisesByUrl[url]) {
                return this.pendingPromisesByUrl[url]
            }

            // Remove the url from failed list
            pull(this.failedAuth, [url])

            // Add the url to the already fetched list
            alreadyFetched.push(url)

            this.pendingPromisesByUrl[url] = api.get(url)
                .then(({ data }) => {
                    // Set the badge data
                    this.badges[url] = data

                    // Fetch nested badge URLs
                    forEach(data.badge_urls, (badgeUrl) => {
                        this.fetch(badgeUrl, alreadyFetched)
                    })
                })
                .catch((e) => {
                    const status = get(e, 'response.status')

                    // If endpoint doesn't support badges add to blacklist
                    if (status === 422) {
                        this.failedPermanently.push(url)
                    }

                    // If endpoint failed due to user being logged out
                    if (status === 403) {
                        this.failedAuth.push(url)
                    }

                    // Remove the badge
                    delete this.badges[url]
                })
                .finally(() => {
                    delete this.pendingPromisesByUrl[url]
                })

            return this.pendingPromisesByUrl[url]
        },
        async refreshAuthFailures() {
            const urls = difference(uniq(this.failedAuth), this.failedPermanently)

            // Clear out the urls failed due to auth
            this.failedAuth = []

            // Retry each url
            each(urls, (url) => {
                this.fetch(url)
                    .catch((e) => console.log(e))
            })
        },
        async refreshAll() {
            events.emit('refresh-badges')
        },
    },
})
