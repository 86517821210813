<template>
    <IonPage>
        <IonHeader class="ion-no-border">
            <!-- Add the header over the content in the fixed slot -->
            <AppHeader
                ref="headerMenu"
                :headerImage="headerImage"
                :has-scrolled="contentScrollY > 2"
                :style="ionicBackgroundStyle"
            >
                <template v-if="moduleTitle" v-slot:title>{{ moduleTitle }}</template>
            </AppHeader>
        </IonHeader>
        <IonContent fullscreen scroll-events @ionScroll="contentScrollY = $event.detail.scrollTop" :style="ionicBackgroundStyle" data-cy="content-module">
            <!-- Position the refresher under the app header in the fixed slot -->
            <IonRefresher v-if="supportsRefresher" slot="fixed" @ionRefresh="doRefresh">
                <IonRefresherContent />
            </IonRefresher>

            <div v-if="loadingId === appModuleId" class="py-10 flex items-center justify-center absolute top-0 left-0 right-0 bottom-0 flex-shrink-0" data-cy="module-loading">
                <UILoading class="w-12 h-12" />
            </div>

            <div v-else-if="error" class="p-4 flex-shrink-0">
                <Error
                    :axiosError="error"
                />
            </div>

            <component
                v-else-if="data"
                :is="component"
                :config="data"
                class="ion-content-scroll-host"
                :data-cy="`module-component-${data?.component}`"
            />
        </IonContent>
        <IonFooter ref="footerMenu" class="ion-no-border" :style="ionicBackgroundStyle" />
    </IonPage>
</template>

<script setup>
    import { provide } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { omit } from 'lodash'
    import Error from '@/components/ui/Error.vue'

    import { statusBar } from '@/services'

    const route = useRoute()
    const router = useRouter()

    const authStore = useAuthStore()
    const moduleStore = useModuleStore()

    const authHelpers = useAuthHelpers()
    const moduleHelpers = useModuleHelpers()

    const props = defineProps({
        moduleId: String,
    })

    const { modules, loadingId } = storeToRefs(moduleStore)

    const component = ref()

    const routeModuleId = ref(route.params.id)
    const error = ref(null)
    const headerMenu = ref(null)
    const footerMenu = ref(null)
    const contentScrollY = ref(0)

    provide('headerMenu', headerMenu)
    provide('footerMenu', footerMenu)

    const appModuleId = computed(() => props.moduleId ?? routeModuleId.value)
    const appModule = computed(() => modules.value[appModuleId.value])
    const data = computed(() => appModule.value?.data)

    const MODULES_WITHOUT_REFRESH = ['Tabs', 'CustomMap']

    const useSolidGrayBackground = computed(() => {
        return data.value?.component === 'TourStep'
    })

    const supportsRefresher = computed(() => {
        return appModule.value?.data?.meta?.refresh_url
            || (appModule.value?.link?.url && !MODULES_WITHOUT_REFRESH.includes(appModule.value?.data?.component))
    })

    const moduleTitle = computed(() => {
        return get(data.value, 'meta.title')
    })

    const headerImage = computed(() => {
        return !!get(data.value, 'meta.header_image')
    })

    const ionicBackgroundStyle = computed(() => {
        // Used by IonContent
        return {
            '--tw-bg-opacity': 1,
            '--background': useSolidGrayBackground.value
                ? 'rgb(243 244 246 / var(--tw-bg-opacity))'
                : 'rgb(255 255 255 / var(--tw-bg-opacity))',
        }
    })

    const authError = (e) => {
        error.value = e
    }

    const goBack = () => {
        router.back()
    }

    const authAbort = () => {
        goBack()
    }

    const loadModule = async (refresh = false) => {
        if (!refresh && data.value) {
            component.value = data.value.component
            return
        }
        error.value = null
        return moduleStore.loadModule(null, refresh)
            .then(() => {
                if (!refresh && isNil(component.value)) {
                    // Don't overwrite component if it already exists
                    component.value = data.value.component
                }
            })
            .catch((e) => {
                if (authHelpers.isAuthenticationException(e)) {
                    authHelpers.handleAuthenticationException(
                        e.response.data,
                        router,
                        (payload) => {
                            const switchedUsers = payload?.userSwitched ?? true

                            if (switchedUsers) {
                                // Go back to the first level module and refresh
                                const moduleId = first(moduleStore.stack)
                                const openModuleCount = moduleStore.stack.length
                                moduleStore.stack = [moduleId]
                                moduleStore.modules = {
                                    [moduleId]: omit(moduleStore.modules[moduleId], 'data'),
                                }
                                moduleHelpers.navigateBackToFirstModule(openModuleCount)
                            } else {
                                // Reload the same view
                                const moduleId = last(moduleStore.stack)
                                router.replace({ name: 'view', params: { id: moduleId } })
                            }
                        },
                        () => {
                            router.back()
                        },
                    )

                    // Prevent error from appearing while the login view launches
                    return
                }

                if (moduleHelpers.isActionException(e)) {
                    moduleHelpers.handleActionException(
                        e.response.data,
                    )
                }

                error.value = e
            })
    }

    const doRefresh = async (e) => {
        return loadModule(true)
            .finally(() => {
                e.target.complete()
            })
    }

    watch(data, (value, oldValue) => {
        // Update component if data changes
        if (get(value, 'component') && value.component !== oldValue?.component) {
            component.value = value.component
        }
    }, { deep: true })

    onMounted(() => {
        console.log('mounted module')
        authStore.events.on('error', authError)
        authStore.events.on('abort', authAbort)

        loadModule()
    })

    onUnmounted(() => {
        authStore.events.off('error', authError)
        authStore.events.off('abort', authAbort)
    })

    onIonViewWillEnter(() => {
        statusBar.setTheme()
    })
</script>
